import dayjs from "dayjs"
import React, { ReactElement, useContext, useRef } from "react"
import { Image } from "./components/Image"
import { Image as ImageResolver } from "../Image/Image"
import { Mdx } from "../Mdx/Mdx"
import { Illustration } from "./components/Illustration"
import {
  ArticleEntity,
  ArticleTOC,
  ArticleTypes,
} from "../../entities/ArticleEntity"
import { Quote } from "./components/Quote"
import { TOC } from "./components/TOC"
import { MarketingTypes } from "../../entities/MarketingEntity"
import { Discover } from "../Marketing/Discover/Discover"
import { Newsletter } from "../Marketing/Newsletter/Newsletter"
import { Faq } from "../Marketing/Faq/Faq"
import { Title } from "./components/Title"
import { Category } from "../Category/Category"
import { H1, Text } from "../Mdx/components"

import ShareIcon from "@heroicons/react/20/solid/ShareIcon"
import { TwitterIcon } from "../Icons/Twitter"
import { FacebookIcon } from "../Icons/Facebook"
import { ThemeContext } from "../Theme/Theme"
import { Interactions } from "./components/Interactions"
import { Link } from "../Link/Link"
import { getAuthorUrl, normalizeI18nUrl } from "../../utils/i18nUrls"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { Button } from "../Button/Button"
import { Bookmark } from "../Bookmark/Bookmark"
import { Follow } from "../Follow/Follow"
import { Bubble } from "./components/Bubble"
import { useShare } from "../../utils/useShare"
import { slugifyForAnchors } from "../../utils/slugifyForAnchors"
import { Recipe } from "./components/Recipe"
import { Product } from "./components/Product"
import { RelatedArticle } from "./components/RelatedArticle"
import { Video } from "./components/Video"
import clsx from "clsx"

const getTableOfContentItems = (
  content: ArticleEntity["content"]
): ArticleTOC["items"] => {
  return content
    .filter(({ type }) => type === ArticleTypes.TITLE)
    .map((item: any) => ({
      label: item.value,
      to: `#${slugifyForAnchors(item.value)}`,
      depth: Number(item.component.replace("h", "")) - 2,
    }))
}

const Icon: React.FC<{
  to: string
  name: string
  icon: ReactElement
}> = props => {
  const styles = useContext(ThemeContext)

  return (
    <a
      href={props.to}
      target="_blank"
      rel="noopener noreferrer"
      className={`p-2 ${styles["anchor"]} transition-colors ease-in-out duration-300 cursor-pointer hover:bg-slate-100 text-slate-900 rounded flex items-center justify-center`}
    >
      <span className="sr-only">{props.name}</span>

      {props.icon}
    </a>
  )
}

const Share: React.FC<{
  title: string
  description: string
  id: string
}> = props => {
  const styles = useContext(ThemeContext)
  const { url, shareable } = useShare()

  return (
    <div className="hidden md:flex items-center ml-auto justify-end">
      <Icon
        name="Twitter"
        to={`https://twitter.com/intent/tweet?url=${url}&text=${encodeURIComponent(
          props.title
        )}`}
        icon={<TwitterIcon className="h-5 w-5" />}
      />

      <Icon
        name="Facebook"
        to={`https://www.facebook.com/sharer.php?u=${url}`}
        icon={<FacebookIcon className="h-5 w-5" />}
      />

      {shareable && (
        <div
          onClick={async () => {
            try {
              await navigator.share({
                url,
                title: props.title,
                text: props.description,
              })
            } catch (e) {}
          }}
          className={`p-2 ${styles["anchor"]} transition-colors ease-in-out duration-300 cursor-pointer hover:bg-slate-100 text-slate-900 rounded flex items-center justify-center`}
        >
          <ShareIcon className="h-5 w-5" />
        </div>
      )}

      <Bookmark id={props.id} />
    </div>
  )
}

const Header: React.FC<{
  updatedAt: any
  title: string
  description: string
  language: string
  author: ArticleEntity["author"]
  category: ArticleEntity["category"]
  id: ArticleEntity["id"]
}> = props => (
  <div className={`flex w-full md:px-0 px-4 items-center`}>
    <Link
      url={normalizeI18nUrl(props.language, "@" + props.author.username)}
      rel="obfuscated"
      className="safari-rounded-full"
    >
      <ImageResolver
        src={props.author.image.src}
        alt={props.author.image.alt}
        className="h-12 w-12 rounded-full"
      />
    </Link>

    <div className="ml-2">
      <div className="flex items-center">
        <Link
          url={normalizeI18nUrl(props.language, "@" + props.author.username)}
          className={`font-medium font-display`}
          rel="obfuscated"
        >
          {props.author?.name}
        </Link>
      </div>

      <p className="text-sm text-slate-700 font-display space-x-1">
        <span>{dayjs(props.updatedAt).format("D MMMM YYYY")}</span>
        <span className="text-slate-300">•</span>
        <Link url={props.category.url} className="hover:underline">
          {props.category.name}
        </Link>
      </p>
    </div>

    <Share title={props.title} description={props.description} id={props.id} />

    <div className="ml-auto md:hidden">
      <Follow id={props.author.id} small />
    </div>
  </div>
)

export const Article: React.FC<ArticleEntity> = props => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <div className="w-full lg:pl-20">
      <article ref={ref} className="pt-8 relative md:pt-14 w-full">
        <div
          className={clsx(
            "max-w-3xl mx-auto",
            (!props.version || props.version === "v1") && "mb-4"
          )}
        >
          <Header
            category={props.category}
            language={props.lang}
            updatedAt={props.updated_at}
            author={props.author}
            title={props.title}
            description={props.description}
            id={props.id}
          />
          <H1 id={props.title}>{props.title}</H1>
          {(!props.version || props.version === "v1") && (
            <Text>{props.description}</Text>
          )}
        </div>

        {(!props.version || props.version === "v1") && (
          <Illustration {...props.illustration} />
        )}

        {props.content?.map((block, index) => {
          if (block.type === ArticleTypes.RICH_TEXT)
            return <Mdx key={index}>{block.content}</Mdx>
          if (block.type === ArticleTypes.TITLE)
            return <Title key={index} {...block} />
          if (block.type === ArticleTypes.IMAGE)
            return <Image key={index} {...block} />
          if (block.type === ArticleTypes.QUOTE)
            return <Quote key={index} {...block} />
          if (block.type === ArticleTypes.RECIPE)
            return <Recipe key={index} {...block} />
          if (block.type === ArticleTypes.PRODUCT)
            return <Product key={index} {...block} />
          if (block.type === ArticleTypes.ARTICLE)
            return <RelatedArticle key={index} article={block.article} />
          if (block.type === ArticleTypes.VIDEO)
            return <Video key={index} {...block} />
          if (block.type === ArticleTypes.TOC)
            return (
              <TOC
                key={index}
                {...block}
                items={getTableOfContentItems(props.content)}
              />
            )
          if (block.type === MarketingTypes.DISCOVER)
            return <Discover key={index} {...block} />
          if (block.type === MarketingTypes.NEWSLETTER)
            return <Newsletter key={index} {...block} />
          if (block.type === MarketingTypes.FAQ)
            return <Faq key={index} {...block} />
          if (block.type === MarketingTypes.CATEGORY)
            return <Category key={index} {...block} />
          if (block.type === MarketingTypes.RELATED_ARTICLES)
            return <Category key={index} {...block} />

          return <></>
        })}
      </article>

      <Bubble
        id={props.id}
        contentRef={ref}
        title={props.title}
        description={props.description}
      />

      <Interactions id={props.id} />

      <footer className="w-full px-4 mt-10 bg-slate-50 py-10 pb-36">
        <div className="max-w-3xl mx-auto">
          <p className="font-display py-2 text-lg font-bold">
            <FormattedMessage id="article/more/title" />
          </p>
          <p className="text-slate-500 pb-2">
            <FormattedMessage id="article/more/description" />
          </p>

          <Category articles={props.relatedArticles} />

          <div className="text-center mt-8">
            <Link
              url={getAuthorUrl(props.author.lang, props.author.username)}
              rel="obfuscated"
            >
              <Button>
                <FormattedMessage id="article/more/button" />
              </Button>
            </Link>
          </div>
        </div>
      </footer>
    </div>
  )
}
