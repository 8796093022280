import { PauseIcon, PlayIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import React, { useEffect, useRef, useState } from "react"
import { ArticleVideo } from "../../../entities/ArticleEntity"
import { Image as ImageResolver } from "../../Image/Image"

export const VideoPlayer: React.FC<
  Omit<ArticleVideo, "id" | "type"> & {
    classNames?: { video?: string; image?: string }
  }
> = props => {
  const [play, setPlay] = useState(false)
  const [loadVideo, setLoadVideo] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    setLoadVideo(true)
  }, [])

  const togglePlay = () => {
    if (play) {
      videoRef.current?.pause()
      setPlay(false)
    } else {
      videoRef.current?.play()
      setPlay(true)
    }
  }

  return (
    <div className="relative">
      <ImageResolver
        className={clsx(
          props.classNames?.image,
          "ring-8",
          props.ring === "white" && "ring-white",
          props.ring === "blue" && "ring-blue-100",
          props.ring === "green" && "ring-emerald-100",
          props.ring === "pink" && "ring-pink-100"
        )}
        src={props.illustration}
        alt={props.alt}
      />

      <video
        ref={videoRef}
        onLoadedData={() => {
          if (props.autoplay) {
            setPlay(true)
            videoRef.current?.play()
          }
        }}
        className={clsx(props.classNames?.video, "absolute inset-0 ")}
        playsInline={props.autoplay}
        loop={props.autoplay}
        muted={props.autoplay}
        controls={false}
      >
        <source src={props.src?.publicURL} type="video/mp4" />
      </video>

      <div
        className={clsx(
          "inset-0 absolute w-full rounded-lg hover:opacity-100 transition-all ease-in-out duration-300 cursor-pointer bg-slate-900 flex items-center justify-center",
          play && "opacity-0 bg-opacity-20",
          !play && "opacity-100 bg-opacity-0 hover:bg-opacity-20"
        )}
        onClick={togglePlay}
      >
        <div>
          {!play && <PlayIcon className="w-10 h-10 text-white" />}
          {play && <PauseIcon className="w-10 h-10 text-white" />}
        </div>
      </div>
    </div>
  )
}

export const Video: React.FC<ArticleVideo> = props => {
  return (
    <div className="px-4 md:px-0 md:max-w-3xl mt-2 mb-4 mx-auto">
      <VideoPlayer
        {...props}
        classNames={{
          video: "w-full rounded-lg safari-rounded-lg",
          image: "w-full rounded-lg safari-rounded-lg",
        }}
      />
    </div>
  )
}
