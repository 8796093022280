import React, { useContext } from "react"
import { useIntl } from "react-intl"
import { ArticleRecipe } from "../../../entities/ArticleEntity"
import { Link } from "../../Link/Link"
import { H2 } from "../../Mdx/components"
import { ThemeContext } from "../../Theme/Theme"

const ListItem: React.FC<{
  value: string
  quantity: number | string
  weight?: string
  url?: string | null
}> = props => {
  const theme = useContext(ThemeContext)

  return (
    <li className="w-full flex text-lg items-center py-2 border-t first:border-t-transparent border-slate-300 border-dashed">
      {!props.url && <span className="">{props.value}</span>}
      {props.url && (
        <Link
          rel="obfuscated"
          url={props.url}
          target="blank"
          className={`underline font-display ${theme.color}`}
        >
          {props.value}
        </Link>
      )}
      <span className="ml-auto font-display">
        {props.quantity} {props.weight}
      </span>
    </li>
  )
}

export const Recipe: React.FC<ArticleRecipe> = props => {
  const intl = useIntl()

  return (
    <section className="max-w-3xl mx-auto">
      <section>
        <H2>{intl.formatMessage({ id: "recipe/informations" })}</H2>

        <ul className="mt-2 px-4 lg:px-0">
          <ListItem
            value={intl.formatMessage({ id: "recipe/informations/portions" })}
            quantity={props.ingredients.portions}
          />
          <ListItem
            value={intl.formatMessage({ id: "recipe/informations/difficulty" })}
            quantity={intl.formatMessage({
              id: "recipe/informations/difficulty/" + props.difficulty,
            })}
          />
          <ListItem
            value={intl.formatMessage({ id: "recipe/informations/time" })}
            quantity={props.preparationDuration + props.cookDuration}
            weight={intl.formatMessage({ id: "recipe/informations/minutes" })}
          />
          <ListItem
            value={intl.formatMessage({ id: "recipe/informations/calories" })}
            quantity={props.calories}
            weight={intl.formatMessage({ id: "recipe/informations/calories" })}
          />
        </ul>
      </section>

      <section className="mt-8">
        <H2>{intl.formatMessage({ id: "recipe/ingredients" })}</H2>

        <ul className="mt-2 px-4 lg:px-0">
          {props.ingredients.items.map(item => (
            <ListItem
              value={item.value}
              quantity={item.quantity}
              url={item.url}
              weight={
                item.weight &&
                intl.formatMessage({
                  id: "recipe/ingredients/weight/" + item.weight,
                  defaultMessage: item.weight,
                })
              }
              key={item.value}
            />
          ))}
        </ul>
      </section>

      <section>
        <H2>{intl.formatMessage({ id: "recipe/tools" })}</H2>
        <ul className="mt-2 px-4 lg:px-0">
          {props.tools.map(tool => (
            <ListItem {...tool} key={tool.value} />
          ))}
        </ul>
      </section>

      <section>
        <H2>{intl.formatMessage({ id: "recipe/steps" })}</H2>

        <ul className="mt-2 px-4 lg:px-0">
          {props.steps.map((step, index) => (
            <li
              id={`step-${index + 1}`}
              className="relative text-lg border-t first:border-t-transparent border-slate-300 border-dashed py-3"
            >
              {step.content}
            </li>
          ))}
        </ul>
      </section>
    </section>
  )
}
