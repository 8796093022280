import React, { useContext } from "react"
import { ArticleTOC } from "../../../entities/ArticleEntity"
import { As } from "../../As/As"
import { ThemeContext } from "../../Theme/Theme"

export const TOC: React.FC<ArticleTOC> = props => {
  const styles = useContext(ThemeContext)

  return (
    <div className="px-4 md:px-0 container mx-auto md:max-w-3xl font-display">
      <As
        component={props.title.component}
        className={`mt-8 md:text-4xl text-3xl font-extrabold tracking-tight`}
      >
        {props.title.value}
      </As>

      <ol className="mt-4 -ml-2 text-lg list-inside list-none">
        {props.items.map(item => (
          <li
            key={item.to + item.label}
            style={{ paddingLeft: `${item.depth * 10}px` }}
          >
            <a
              href={item.to}
              className={`${styles.anchor} inline-block px-2 py-1 rounded transition-colors ease-in-out duration-300`}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ol>
    </div>
  )
}
