import React, { useContext } from "react"
import { ArticleEntity } from "../../../entities/ArticleEntity"
import { Category } from "../../Category/Category"
import { ThemeContext } from "../../Theme/Theme"

export const RelatedArticle: React.FC<{ article: ArticleEntity }> = props => {
  const theme = useContext(ThemeContext)

  return (
    <div className="md:max-w-3xl mx-auto px-4 md:px-0 my-8">
      <div
        className={`w-full border-l-4 border-emerald-500 ${theme.border} pl-4 md:pl-8 py-4`}
      >
        <Category articles={[props.article]} hideFooter noPadding />
      </div>
    </div>
  )
}
