import React from "react"
import { useIntl } from "react-intl"
import { ArticleProduct, ArticleRecipe } from "../../../entities/ArticleEntity"
import { Button } from "../../Button/Button"
import { Image } from "../../Image/Image"
import { Link } from "../../Link/Link"

export const Product: React.FC<ArticleProduct> = props => {
  const intl = useIntl()

  return (
    <div className="max-w-3xl px-4 md:px-0 block items-center mx-auto md:flex border-t border-b border-transparent py-6">
      <Image
        className="md:w-56 md:h-56 w-full flex-shrink-0 border border-slate-200 rounded-lg"
        {...props.product.image}
      />

      <div className="md:ml-6 md:flex mt-4 md:mt-0 flex-col">
        <div className="font-display text-slate-700 text-sm">Affiliation</div>
        <div className="font-display font-medium text-2xl">
          {props.product.name}
        </div>
        <div className="text-slate-700 mt-1">{props.product.description}</div>

        <div className="mt-4">
          {props.product.buttons.map(({ url, theme, label }) => (
            <Link
              url={url}
              rel="obfuscated"
              target="blank"
              className="inline mr-2"
              key={url}
            >
              <Button theme={theme === "secondary" ? "outline" : "solid"}>
                {label}
              </Button>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
