import ShareIcon from "@heroicons/react/24/solid/ShareIcon"
import clsx from "clsx"
import React, { useEffect, useRef, useState } from "react"
import { Like } from "../../Like/Like"
import { useShare } from "../../../utils/useShare"
import { CommentButton } from "../../CommentButton/CommentButton"

export const Bubble: React.FC<{
  contentRef: any
  id: string
  title: string
  description: string
}> = props => {
  const { url, shareable } = useShare()
  const bubbleRef = useRef<HTMLDivElement>(null)
  const [showInteraction, setShowInteraction] = useState(true)

  function calculatePosition() {
    const scrollHeight = props.contentRef.current?.scrollHeight || 0

    if (
      bubbleRef.current?.classList.contains("__show__") &&
      window.scrollY + window.innerHeight > scrollHeight
    ) {
      setShowInteraction(false)
    }

    if (
      bubbleRef.current?.classList.contains("__hidden__") &&
      window.scrollY + window.innerHeight < scrollHeight
    ) {
      setShowInteraction(true)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", calculatePosition)

    return () => {
      window.removeEventListener("scroll", calculatePosition)
    }
  }, [])

  return (
    <div
      ref={bubbleRef}
      className={clsx(
        "fixed z-20 pointer-events-none pb-16 lg:pb-10 lg:pl-20 text-slate-900 left-0 right-0 bottom-0",
        showInteraction && "flex __show__",
        !showInteraction && "hidden __hidden__"
      )}
    >
      <div className="w-full">
        <div
          className={clsx(
            "pointer-events-auto mx-auto p-2 bg-white rounded-full shadow-lg",
            shareable ? "w-48" : "w-28"
          )}
        >
          <div
            className={clsx(
              "grid divide-x",
              shareable ? "grid-cols-3" : "grid-cols-2"
            )}
          >
            <div className="flex items-center justify-center">
              <Like id={props.id} />
            </div>

            <div className="flex items-center justify-center">
              <CommentButton id={props.id} />
            </div>

            {shareable && (
              <div
                className="flex items-center justify-center"
                onClick={async () => {
                  try {
                    await navigator.share({
                      url,
                      title: props.title,
                      text: props.description,
                    })
                  } catch (e) {}
                }}
              >
                <ShareIcon className="h-6 w-6" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="hidden lg:block w-80 h-1 flex-shrink-0"></div>
    </div>
  )
}
